import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useGeolocated } from "react-geolocated";
import { BiCurrentLocation } from "react-icons/bi";
import InputField from "../components/inputField";
import AnimatedImageCard from "../components/animated-image-card";
import ContactFormHome from "../components/contact-form-home";
import CartContext from "../context/CartContext";
import VerticalListingsPropertyCard from "../components/vertical-listings-property-card";
import ExpertCard from "../components/expert-card";
import { BgImage } from "gbimage-bridge";
import Accordion from "react-bootstrap/Accordion";
import StarsIcon from "../images/5stars.svg";
import Testimonials from "../components/testimonials";

mapboxgl.workerClass =
	require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const PopularLocationPage = ({
	data: {
		popLocation,
		heroInterior,
		paddington,
		canaryWharf,
		piccadilly,
		westminster,
		site,
		bottomImage1,
		defaultBannerImage,
	},
	pageContext: { properties },
}) => {
	const { settingVal, onLocationChange } = React.useContext(CartContext);

	const siteUrl = site.siteMetadata.siteUrl;

	const [shortOfficesNew, setShortOfficesNew] = useState([]);

	const defaultImage = bottomImage1;

	// Check if a featured image is available and set it initially
	const initialImage = popLocation.popularLocationsFields.featuredImage
		? popLocation.popularLocationsFields.featuredImage.localFile
		: defaultImage;

	const [bottomImage, setBottomImage] = useState(initialImage);

	// Extract banner data from GraphQL query
	const { banner } = popLocation.popularLocationsFields;

	// Set default values for banner fields
	const bannerHeading =
		banner?.bannerHeading || "Need something more specific?";
	const bannerBody = banner?.bannerBody || (
		<span>
			Use our free office calculator for instant pricing and size estimates -
			then request
			<br className="d-none d-xl-block" /> your tailored report!
		</span>
	);
	const bannerButtonTitle = banner?.bannerButton?.title || "Office Calculator";
	const bannerButtonUrl = banner?.bannerButton?.url || "/calculator";

	const bannerImageData = getImage(
		banner?.bannerBackgroundImage?.localFile?.childImageSharp
			?.gatsbyImageData ||
			defaultBannerImage.localFile.childImageSharp.gatsbyImageData
	);
	const bannerImageAlt =
		banner?.bannerBackgroundImage?.altText ||
		defaultBannerImage.altText ||
		"Banner Background";

	useEffect(() => {
		setShortOfficesNew(properties);
	}, [properties]);

	useEffect(() => {
		if (typeof Window !== "undefined") {
			onLocationChange(
				popLocation.popularLocationsFields.latitude,
				popLocation.popularLocationsFields.longitude
			);
			settingVal(
				popLocation.popularLocationsFields.locationName,
				"locationValue"
			);
			settingVal("Office Type", "selectedOffice");
			settingVal({ min: 0, max: 75, reset: true }, "numberOfPeople");
			settingVal(
				{
					min: 0,
					max: 150000,
					minPerDesk: 0,
					maxPerDesk: 2000,
					perDesk: false,
				},
				"budgetPerDesk"
			);
			settingVal("Sort", "sortedBy");
			settingVal(10, "distanceAmount");
			settingVal([], "selectedFeatures");

			// Set the filtered properties passed from pageContext
			settingVal(properties, "shortOffices");
			console.log("test");
		}
	}, []);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: `${popLocation.seoFields.metaTitle}`,
				item: {
					url: `${siteUrl}/${popLocation.popularLocationsFields.url}`,
					id: `${siteUrl}/${popLocation.popularLocationsFields.url}`,
				},
			},
		],
	};

	const { coords, isGeolocationAvailable, isGeolocationEnabled } =
		useGeolocated({
			positionOptions: {
				enableHighAccuracy: true,
			},
			isOptimisticGeolocationEnabled: false,
			userDecisionTimeout: 5000,
		});

	const OnLocationChangeGeo = () => {
		if (isGeolocationAvailable && isGeolocationEnabled && coords) {
			onLocationChange(coords.latitude, coords.longitude);
			settingVal("Your location", "locationValue");
		} else {
			alert("Please allow access to your location on your browser");
		}
	};

	// Split properties into first three and remaining
	const firstThreeProperties = shortOfficesNew.slice(0, 3);
	const secondThreeProperties = shortOfficesNew.slice(3, 6);
	const remainingProperties = shortOfficesNew.slice(6);

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={popLocation.seoFields?.metaTitle}
				description={popLocation.seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/${popLocation.popularLocationsFields.url}`,
					title: `${popLocation.seoFields?.opengraphTitle}`,
					description: `${popLocation.seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${popLocation.seoFields?.image?.localFile.publicURL}`,
							width: `${popLocation.seoFields?.image?.localFile.childImageSharp.original.width}`,
							height: `${popLocation.seoFields?.image?.localFile.childImageSharp.original.height}`,
							alt: `${popLocation.seoFields?.image?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<Container>
						<Row className="align-items-center">
							<Col xs={10} sm={11} xl={6}>
								<div className="pb-1 d-flex align-items-center">
									<InputField staticPage propertiesPage />
								</div>
							</Col>
							<Col className="pb-2 d-xl-none" xs={2} sm={1}>
								<Link
									className="align-self-center"
									style={{ cursor: "pointer" }}
									onClick={OnLocationChangeGeo}
									to="/properties"
								>
									<BiCurrentLocation className="fs-3 card-link" />
								</Link>
							</Col>
							<Col className="text-end d-none d-xl-block" lg={6}>
								<Button
									style={{ borderRadius: "200px", fontWeight: "700" }}
									as={Link}
									onClick={() => settingVal(1, "distanceAmount")}
									to="/properties"
									variant="primary"
									className="w-100 w-md-auto px-4 text-white"
								>
									Show map
								</Button>
							</Col>
						</Row>
					</Container>
					<hr className="text-med-grey py-0 my-0" />
					<Container className="">
						<Row>
							<Col className="text-center pt-4">
								<Button
									style={{ borderRadius: "200px", fontWeight: "700" }}
									as={Link}
									onClick={() => settingVal(1, "distanceAmount")}
									to="/properties"
									variant="outline-primary"
									className="w-100 w-md-auto px-4"
								>
									REFINE YOUR SEARCH
								</Button>
							</Col>
						</Row>
					</Container>
					<section className="pt-4 pb-5">
						{/* First Container with first 3 properties */}
						<Container>
							{firstThreeProperties.length > 0 ? (
								<>
									<Row className="h-100">
										<Col>
											<Row className="position-relative mt-0 py-0 mb-0">
												{firstThreeProperties.map((property, i) => (
													<Col
														className="mb-5"
														xs={12}
														md={6}
														lg={6}
														xl={4}
														key={property.id || i}
													>
														<VerticalListingsPropertyCard
															property={property}
															index={i}
														/>
													</Col>
												))}
											</Row>
										</Col>
									</Row>
								</>
							) : (
								<Row>
									<Col>
										<p>No properties available at the moment.</p>
									</Col>
								</Row>
							)}
						</Container>

						{/* Banner in Fluid Container */}
						<Container fluid className="px-0">
							<div className="banner-container position-relative">
								<BgImage
									className="py-5 pt-lg-9 px-4 px-md-0"
									image={bannerImageData}
								>
									<div
										style={{ zIndex: 1, background: "#00000050" }}
										className="position-absolute w-100 h-100 top-0 start-0"
									></div>
									<Row className="justify-content-center">
										<Col md={10} lg={7}>
											<div
												style={{ zIndex: 1 }}
												className="text-center text-white position-relative"
											>
												<h2 className="fs-1 text-white">{bannerHeading}</h2>
												<p className="fs-5 text-white">{bannerBody}</p>
												<Button
													as={Link}
													to={bannerButtonUrl}
													className={`text-white btn btn-primary  me-md-3 px-4  w-100 w-md-auto fw-bold `}
												>
													{bannerButtonTitle}
												</Button>
											</div>
										</Col>
									</Row>
									<Row className="justify-content-center mt-5">
										<Col md={11} className="text-md-end text-center z-1">
											<h3 className="fs-5 text-white me-md-4 pe-md-1">
												Free Expert Guidance
											</h3>
											<div className="d-flex align-items-center justify-content-center justify-content-md-end">
												<p className="fw-bold fs-5 py-0 my-0 text-white">
													Rated{" "}
													<a
														href="#review-section-id"
														className="fw-bold"
														style={{ color: "#FEA500" }}
													>
														5.0
													</a>
												</p>
												<a href="#review-section-id">
													<img
														className="me-3 ms-2"
														src={StarsIcon}
														style={{
															maxWidth: "80px",
														}}
													/>
												</a>
												<p className="fw-bold fs-5 py-0 my-0 text-white">
													Google
												</p>
											</div>
										</Col>
									</Row>
								</BgImage>
							</div>
						</Container>

						<Container>
							{secondThreeProperties.length > 0 ? (
								<>
									<Row className="h-100 mt-5">
										<Col>
											<Row className="position-relative mt-0 py-0 mb-0">
												{secondThreeProperties.map((property, i) => (
													<Col
														className="mb-5"
														xs={12}
														md={6}
														lg={6}
														xl={4}
														key={property.id || i}
													>
														<VerticalListingsPropertyCard
															property={property}
															index={i}
														/>
													</Col>
												))}
											</Row>
										</Col>
									</Row>
								</>
							) : (
								<Row>
									<Col>
										<p>No properties available at the moment.</p>
									</Col>
								</Row>
							)}
						</Container>

						<section
							style={{ scrollMarginTop: "150px" }}
							id="review-section-id"
							className=""
						>
							<Testimonials title={"Testimonials"} />
						</section>
						{/* Second Container with remaining properties */}
						<Container>
							{remainingProperties.length > 0 ? (
								<>
									<Row className="h-100">
										<Col>
											<Row className="position-relative mt-5 py-0 mb-0">
												{remainingProperties.map((property, i) => (
													<Col
														className="mb-5"
														xs={12}
														md={6}
														lg={6}
														xl={4}
														key={property.id || i}
													>
														<VerticalListingsPropertyCard
															property={property}
															index={i + 3} // Adjust index if needed
														/>
													</Col>
												))}
											</Row>
										</Col>
									</Row>
								</>
							) : null}
							<Row>
								<Col lg={12} className="text-center">
									<Button
										style={{ borderRadius: "200px" }}
										as={Link}
										onClick={() => settingVal(1, "distanceAmount")}
										to="/properties"
										variant="dark-blue w-100 w-md-auto px-4"
									>
										REFINE YOUR SEARCH
									</Button>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5">
						<Container>
							<Row className="g-5 align-items-center">
								<Col lg={4}>
									<div style={{ borderRadius: "10px", overflow: "hidden" }}>
										<GatsbyImage
											image={getImage(bottomImage)}
											className="w-100"
											alt={`Office space in ${popLocation.title}`}
										/>
									</div>
								</Col>
								<Col lg={8}>
									<h1>
										Office space in{" "}
										{popLocation.popularLocationsFields.locationName}
									</h1>
									{parse(popLocation.popularLocationsFields.description)}
								</Col>
							</Row>
						</Container>
					</section>
					<section
						className="bg-white position-relative mt-4"
						style={{ zIndex: 1 }}
					>
						<Container className="bg-white my-lg-6 my-3 my-md-5">
							<Row className="g-4">
								<Col lg={6}>
									<div
										style={{
											boxShadow: "0px 1px 20px #00000029",
											borderRadius: "12px",
										}}
										className="py-3 py-md-5 px-3 px-md-5 mt-2 mb-3 bg-white"
									>
										<Row>
											<Col>
												<h2 className="mb-3 text-start">Get in touch</h2>
											</Col>
											<Row>
												<Col>
													<p className="mb-5 text-start">
														Fill in your contact details below and we'll put you
														in touch with one of our representatives
													</p>
												</Col>
											</Row>
										</Row>
										<ContactFormHome />
									</div>
									<ExpertCard />
								</Col>
								<Col lg={6} className="ps-lg-5">
									<Row className="pt-3 pt-lg-6 pb-3 pb-lg-5">
										<Col className="text-center">
											<h2>Popular Locations</h2>
										</Col>
									</Row>
									<Row className="g-3 pb-5">
										<Col xs={6}>
											<AnimatedImageCard imageData={paddington} />
										</Col>
										<Col xs={6}>
											<AnimatedImageCard imageData={canaryWharf} />
										</Col>
										<Col xs={6}>
											<AnimatedImageCard imageData={piccadilly} />
										</Col>
										<Col xs={6}>
											<AnimatedImageCard imageData={westminster} />
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</section>
					{/* <section className="faq-section py-5">
						<Container>
							<h2 className="text-center mb-4">Frequently Asked Questions</h2>
							<Accordion className="custom-accordion py-5" defaultActiveKey="0">
								{popLocation.popularLocationsFields.locationFaqs?.map(
									(faq, index) => (
										<Accordion.Item
											className="pb-3"
											eventKey={index.toString()}
											key={index}
										>
											<Accordion.Header>
												<strong className="fs-5 ">{faq?.question}</strong>
											</Accordion.Header>
											<Accordion.Body>{faq?.answer}</Accordion.Body>
										</Accordion.Item>
									)
								)}
							</Accordion>
						</Container>
					</section> */}
				</Layout>
			</div>
		</>
	);
};

export default PopularLocationPage;

export const pageQuery = graphql`
	query PopularLocationById($id: String!) {
		popLocation: wpPopularLocation(id: { eq: $id }) {
			seoFields {
				metaTitle
				metaDescription
				opengraphDescription
				opengraphTitle
				productSchema
				image {
					altText
					localFile {
						childImageSharp {
							original {
								height
								width
							}
						}
						publicURL
					}
				}
			}
			title
			popularLocationsFields {
				banner {
					bannerBody
					bannerHeading
					bannerBackgroundImage {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: FULL_WIDTH
									placeholder: BLURRED
								)
							}
						}
					}
					bannerButton {
						title
						url
					}
				}
				featuredImage {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 70
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				locationFaqs {
					question
					answer
				}
				description
				latitude
				longitude
				locationName
				metaDescription
				property1
				property2
				property3
				property4
				property5
				url
			}
		}
		defaultBannerImage: wpMediaItem(title: { eq: "CTA Banner New Use" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: FULL_WIDTH
						placeholder: BLURRED
					)
				}
			}
		}
		heroInterior: wpMediaItem(title: { eq: "hero-interior" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 70
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		paddington: wpMediaItem(title: { eq: "Bank" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 30
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		bottomImage1: file(relativePath: { eq: "Bank-Static-Image.jpg" }) {
			childImageSharp {
				original {
					height
					width
				}
				gatsbyImageData(
					formats: [AUTO, WEBP]
					quality: 70
					transformOptions: { cropFocus: CENTER, fit: CONTAIN }
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
		}
		canaryWharf: wpMediaItem(title: { eq: "London Bridge" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 30
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		piccadilly: wpMediaItem(title: { eq: "Mayfair" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 30
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		westminster: wpMediaItem(title: { eq: "Shoreditch-Static-Page-square" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 30
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
